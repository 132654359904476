@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=IBM+Plex+Mono&family=Inconsolata:wght@300;500;900&family=Inter&display=swap');html,
#root,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Inconsolata', monospace;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
